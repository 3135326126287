.home {
  width: 100vw;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  font-size: 1rem;
  color: black;
  background-color: #FFF1F1;
  .header{
    padding: .38rem 3.2rem;
    width: 100%;
    .container{
      display: flex;
      align-items:center;
      justify-content: flex-start;
      .logo{
        display: flex;
        img{
          height: .45rem;
        }
      }
    }
  }
  .main{
    flex: 1;
    padding: 0 3.2rem;
    width: 100%;
    .container{
      display: flex;
      align-items:center;
      justify-content: space-between;
      height: 100%;
      .left{
        margin-top: -0.3rem;
        .title{
          font-family: 'HSSBlod';
          font-size: .84rem;
          line-height: .98rem;
          font-weight: bold;
          margin: 0;
        }
        .btn_group{
          display: flex;
          align-items: center;
          justify-content: flex-start;
          margin: 0 -0.12rem;
          margin-top: .6rem;
          button{
            display: flex;
            align-items: center;
            justify-content: center;
            padding: .12rem 0;
            padding-left: .28rem;
            padding-right: .4rem;
            border-radius: .4rem;
            border: none;
            // min-width: 2.2rem;
            box-sizing: content-box;
            background-color: rgb(0, 0, 0);
            color: rgb(255, 255, 255);
            margin: 0 .16rem;
            cursor: pointer;
            img{
              width: .26rem;
              min-width: 20px;
              height: auto;
              margin-right: .19rem;
            }
            .text{
              text-align: left;
              p{
                font-family: 'HSSRegular';
                font-size: .14rem;
                line-height: 1.2;
                margin: 0;
              }
              .name{
                font-family: 'HSSBlod';
                font-weight: bold;
                font-size: .18rem;
                line-height: 1.2;
              }
            }
          }
        }
      }
      .right{
        img{
          width: 6.4rem;
        }
      }
    }
  }
  .footer{
    padding: 0 3.2rem;
    width: 100%;
    padding-bottom: .42rem;
    .container{
      display: flex;
      align-items:center;
      justify-content:space-between;
      color: #000000;
      font-size: .12rem;
      line-height: 1.4;
      font-family: 'HSSMedium';
      .left{
        .nav{
          display: flex;
          align-items: center;
          justify-content: flex-start;
          .item{
            position: relative;
            &:hover{
              text-decoration: underline;
            }
          }
          .shu{
            width: .01rem;
            height: .14rem;
            background-color: #5E5E5E;
            margin: 0 .25rem;
          }
        }
      }
    }
  }
}
