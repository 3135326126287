@font-face {
  font-family: HSSBlod;
  src: url(../../assets/font/HarmonyOS_Sans_SC_Bold.ttf);
}
@font-face {
  font-family:HSSMedium;
  src:url(../../assets/font/HarmonyOS_Sans_SC_Medium.ttf);
}

@font-face {
  font-family:HSSRegular;
  src:url(../../assets/font/HarmonyOS_Sans_SC_Regular.ttf);
}