.bannerLine{
  #svg_1{
    stroke-dasharray: 0,3573;
    stroke-dashoffset: 0;
    animation: animation 3s ease-out  ;
    -webkit-animation: animation 3s ease-out ;
    -webkit-animation-fill-mode: both;
  }
}
@keyframes animation {

  0%{
    stroke-dasharray: 0, 3573;
    stroke-dashoffset: 0;
  }
  100%{
    stroke-dasharray: 3573, 0;
    stroke-dashoffset: 0;
  }
}
