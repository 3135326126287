iframe {
  display: none;
}


html,body{
  width: 100%;
  background-color: #FFF1F1;
}

body{
  overflow-x: hidden;
}